import React from 'react'
import he from 'he' // Used to decode special characters/HTML entities contained in YouTube video titles.
import EditorIcon from '../../../misc/EditorIcons'
import Icon from '../../../misc/Icons'
import { fetchVideoDetails } from '../../../../prosemirror/utils/editorActions/addVideo'
// eslint-disable-next-line import/no-cycle
import { showEditVideoModal } from '../../utils/showEditVideoModal'
import { extractIdFromYouTubeUrl } from '../../../../utils/extractIdFromYouTubeUrl'

// Has search bar to search YT.  Can also paste in YT url which triggers searchYT to fetch video from YT API
// Have put some UI in place if we want to make YT search a pro limited feature in the future
// isProLimited
// showWarning

const YOUTUBE_SEARCH_API_URL = `https://www.googleapis.com/youtube/v3/search?type=video&part=snippet&maxResults=50&safeSearch=strict&key=${process.env.REACT_APP_YT_API_KEY}`

class EditorInsertVideoModalVideoResult extends React.Component {
  render() {
    const { snippet } = this.props
    const url = `https://youtube.com/watch?v=${this.props.videoID}`
    let thumbnail
    if (snippet.thumbnails.maxres) {
      thumbnail = snippet.thumbnails.maxres.url
    } else if (snippet.thumbnails.standard) {
      thumbnail = snippet.thumbnails.standard.url
    } else if (snippet.thumbnails.high) {
      thumbnail = snippet.thumbnails.high.url
    } else if (snippet.thumbnails.default) {
      thumbnail = snippet.thumbnails.default.url
    } else {
      thumbnail = `https://i.ytimg.com/vi/${this.props.videoID}/sddefault.jpg`
    }
    return (
      <div onClick={() => { this.props.selectVideo(url) }} className='editor-insertVideoModal-videoResult notranslate'>
        <div className='editor-insertVideoModal-videoResult-thumbnail'>
          <img src={thumbnail} alt='' />
        </div>
        <div className='editor-insertVideoModal-videoResult-info'>
          <div className='editor-insertVideoModal-videoResult-info-title'>
            {he.decode(this.props.title)}
          </div>
          <div className='editor-insertVideoModal-videoResult-info-author'>
            {this.props.author}
          </div>
        </div>
      </div>
    )
  }
}

class EditorInsertVideoModal extends React.Component {
  constructor(props) {
    super(props)
    this.selectVideo = this.selectVideo.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.searchYT = this.searchYT.bind(this)
    this.clearSearch = this.clearSearch.bind(this)
    this.onInputChange = this.onInputChange.bind(this)
    this.handleLoadMore = this.handleLoadMore.bind(this)

    let searchTerm = ''
    let searchInput = ''
    let searchResults = []
    let noResults = false
    let nextPageToken = null
    if (props.searchState) {
      searchTerm = props.searchState.searchTerm
      searchInput = props.searchState.searchInput
      searchResults = props.searchState.searchResults
      noResults = props.searchState.noResults
      nextPageToken = props.searchState.nextPageToken
    }
    this.state = {
      searchTerm,
      searchInput,
      searchResults,
      isSearching: false,
      noResults,
      nextPageToken,
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyDown)
    if (this.props.searchState && this.props.searchState.scrollTop) {
      const el = document.getElementById('ytSearchResults')
      if (el) {
        el.scrollTop = this.props.searchState.scrollTop
      }
    }
    if (window.analytics) {
      window.analytics.page('Insert Video Modal')
    }
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyDown)
  }

  selectVideo(url) {
    const {
      insertPos, isQuestion, questionPos, questionNode,
    } = this.props
    fetchVideoDetails(url).then((video) => {
      if (video) {
        const autoplay = true
        const {
          searchTerm, searchInput, searchResults, nextPageToken,
        } = this.state
        let searchState = {
          searchTerm,
          searchInput,
          searchResults,
          nextPageToken,
          isUrlInput: false,
        }
        const isInsertFlow = true
        let isURLInput = false
        if (searchInput) {
          const videoId = extractIdFromYouTubeUrl(searchInput)
          isURLInput = !!videoId
        }
        if (isURLInput) {
          searchState = {
            searchTerm: '',
            searchInput: '',
            searchResults: [],
            nextPageToken: null,
            isUrlInput: true,
          }
        }
        const el = document.getElementById('ytSearchResults')
        let scrollTop
        if (el) {
          scrollTop = el.scrollTop
        }
        searchState.scrollTop = scrollTop
        this.props.closeInsertVideoModal()
        showEditVideoModal(isInsertFlow, searchState, autoplay, video, insertPos, isQuestion, questionPos, questionNode)
      } else {
        this.props.closeInsertVideoModal()
      }
    })
  }

  searchYT() {
    this.setState({ isSearching: true, noResults: false, searchResults: [] })
    if (window.analytics) {
      window.analytics.track('YouTube search', {
        searchTerm: this.state.searchInput,
      })
    }
    const selfURL = `${YOUTUBE_SEARCH_API_URL}&q=${this.state.searchInput}`
    return fetch(selfURL)
      .then((response) => response.json())
      .then((responseJson) => {
        const searchResults = []
        responseJson.items.forEach((item) => {
          if (item.snippet && item.snippet.liveBroadcastContent === 'none') {
            searchResults.push(item)
          }
        })
        let noResults = false
        if (searchResults.length === 0) {
          noResults = true
        }

        this.setState((prevState) => ({
          nextPageToken: responseJson.nextPageToken,
          searchResults,
          isSearching: false,
          searchTerm: prevState.searchInput,
          noResults,
        }))
        return responseJson
      })
      .catch(() => {})
  }

  handleLoadMore() {
    this.setState({ isSearching: true })
    const selfURL = `${YOUTUBE_SEARCH_API_URL}&pageToken=${this.state.nextPageToken}&q=${this.state.searchInput}`
    return fetch(selfURL)
      .then((response) => response.json())
      .then((responseJson) => {
        const { searchResults } = this.state
        responseJson.items.forEach((item) => {
          if (item.snippet && item.snippet.liveBroadcastContent === 'none') {
            searchResults.push(item)
          }
        })
        let noResults = false
        if (searchResults.length === 0) {
          noResults = true
        }
        if (window.analytics) {
          window.analytics.track('YouTube load more', {
            searchTerm: this.state.searchInput,
          })
        }
        this.setState((prevState) => ({
          nextPageToken: responseJson.nextPageToken,
          searchResults,
          isSearching: false,
          searchTerm: prevState.searchInput,
          noResults,
        }))
        return responseJson
      })
      .catch(() => {})
  }

  handleKeyDown(e) {
    e.stopPropagation()
    if (e.keyCode === 13) {
      let isURLInput = false
      const { searchInput } = this.state
      if (searchInput) {
        const videoId = extractIdFromYouTubeUrl(searchInput)
        isURLInput = !!videoId
      }
      if (isURLInput) {
        this.selectVideo(searchInput)
      } else {
        this.searchYT()
      }
    }
    if (e.keyCode === 27) { // escape key
      this.props.closeInsertVideoModal()
    }
  }

  clearSearch() {
    this.setState({
      searchResults: [], isSearching: false, searchTerm: '', noResults: false, searchInput: '',
    })
    const searchInput = document.getElementById('insertVideoSearchInput')
    if (searchInput) {
      searchInput.focus()
    }
  }

  onInputChange(e) {
    this.setState({ searchInput: e.target.value })
    const videoId = extractIdFromYouTubeUrl(e.target.value)
    if (videoId) {
      this.selectVideo(e.target.value)
      if (window.analytics) {
        window.analytics.track('Paste YouTube url', {
          url: e.target.value,
        })
      }
    }
    if (e.target.value === '') { // remove search results if backspace to clear out search term
      this.clearSearch()
    }
  }

  render() {
    const { searchTerm, searchInput } = this.state
    let searchInputIsEmpty = true
    if (searchInput && searchInput.length > 0) {
      searchInputIsEmpty = false
    }

    const isProLimited = false // show when run out per month
    const showWarning = false // show when X% or X searches remaining

    let isURLInput = false
    if (searchInput) {
      const videoId = extractIdFromYouTubeUrl(searchInput)
      isURLInput = !!videoId
    }

    const { isSearching, noResults, searchResults } = this.state
    const hasResults = this.state.searchResults.length > 0
    let showSearchHint = false // show search hint if input is non empty and different from last search term
    if (!searchInputIsEmpty && searchInput !== searchTerm) {
      showSearchHint = true
    }
    const showClearButton = (searchInput === searchTerm) && (noResults || hasResults) && isSearching === false
    const showCancelButton = searchInputIsEmpty

    return (
      <div className='editor-insertVideoModalContainer'>
        <div className='editor-insertVideoModal'>
          <div className='editor-insertVideoModal-searchBar'>

            {!isProLimited && (
            <input
              id='insertVideoSearchInput'
              className={`editor-insertVideoModal-searchBar-input ${showSearchHint ? 'editor-insertVideoModal-searchBar-input--showSearchHint' : 'editor-insertVideoModal-searchBar-input--hideSearchHint'}${isSearching ? ' editor-insertVideoModal-searchBar-input--isSearching' : ''}${showClearButton ? ' editor-insertVideoModal-searchBar-input--showClearButton' : ''}`}
              placeholder='Search YouTube or Paste YouTube Link'
              onChange={this.onInputChange}
              value={searchInput}
              autoFocus
            />
            )}
            {isProLimited && (
            <input
              id='insertVideoSearchInput'
              className={`editor-insertVideoModal-searchBar-input ${showSearchHint ? 'editor-insertVideoModal-searchBar-input--showSearchHint' : 'editor-insertVideoModal-searchBar-input--hideSearchHint'}${isSearching ? ' editor-insertVideoModal-searchBar-input--isSearching' : ''}`}
              placeholder='Paste YouTube Link'
              onChange={this.onInputChange}
              value={searchInput}
              autoFocus
            />
            )}

            <div className='editor-insertVideoModal-searchBar-searchBarUI'>
              <div className={`editor-insertVideoModal-searchBar-searchBarUI-iconContainer ${isURLInput ? 'editor-insertVideoModal-searchBar-searchBarUI-iconContainer--url' : ''}`}>
                {!isURLInput &&
                <Icon name='magnifying-glass' />}
                {isURLInput &&
                <EditorIcon name='insertVideoLink' />}
              </div>
              {showClearButton && (
              <button onClick={this.clearSearch} className='editor-insertVideoModal-searchBar-searchBarUI-clearBtn'>
                Clear
              </button>
              )}
              {showCancelButton && (
              <button onClick={this.props.closeInsertVideoModal} className='editor-insertVideoModal-searchBar-searchBarUI-cancelBtn'>
                Cancel
              </button>
              )}

              {!isSearching && !isURLInput && (
              <button className='editor-insertVideoModal-searchBar-searchBarUI-hint' onClick={this.searchYT}>
                <div className='editor-insertVideoModal-searchBar-searchBarUI-hint-searchBtn'>
                  Search
                </div>
                <div className='editor-insertVideoModal-searchBar-searchBarUI-hint-secondaryLabel'>
                  Press
                </div>
                <div className='editor-insertVideoModal-searchBar-searchBarUI-hint-key'>
                  Enter
                  {' '}
                  <Icon name='enterKey' />
                </div>
                <div className='editor-insertVideoModal-searchBar-searchBarUI-hint-secondaryLabel'>
                  to Search
                </div>
              </button>
              )}
              {(isSearching === true || isURLInput) && (
              <div className='editor-insertImageModal-searchBar-searchBarUI-searchingHint'>
                <div className='editor-insertImageModal-searchBar-searchBarUI-searchingHint-spinnerContainer'>
                  <div className='editor-insertImageModal-searchBar-searchBarUI-searchingHint-spinner' />
                </div>
              </div>
              )}
            </div>
            {isProLimited && this.state.searchResults.length === 0 && (
            <div className='editor-insertVideoModal-searchBar-proLimitedMessageContainer'>
              <div className='editor-insertVideoModal-searchBar-proLimitedMessage'>
                <div className='editor-insertVideoModal-searchBar-proLimitedMessage-iconContainer'>
                  🚀
                </div>
                <div className='editor-insertVideoModal-searchBar-proLimitedMessage-title'>
                  You've used all your free monthly YouTube searches
                </div>
                <div className='editor-insertVideoModal-searchBar-proLimitedMessage-sub'>
                  Start a free trial of Plickers Pro for unlimited search and more.
                  {' '}
                  <span>Learn more</span>
                </div>
              </div>
            </div>
            )}
            {showWarning && this.state.searchResults.length === 0 && (
            <div className='editor-insertVideoModal-searchBar-proLimitedMessageContainer'>
              <div className='editor-insertVideoModal-searchBar-proLimitedMessage'>
                <div className='editor-insertVideoModal-searchBar-proLimitedMessage-iconContainer'>
                  👋
                </div>
                <div className='editor-insertVideoModal-searchBar-proLimitedMessage-title'>
                  You've almost used up your free monthly YouTube searches
                </div>
                <div className='editor-insertVideoModal-searchBar-proLimitedMessage-sub'>
                  Start a free trial of Plickers Pro for unlimited search and more.
                  {' '}
                  <span>Learn more</span>
                </div>
              </div>
            </div>
            )}
          </div>

          {searchResults.length === 0 && (
          <div className={`editor-insertVideoModal-footer ${isSearching ? 'editor-insertVideoModal-footer--isSearching' : ''}`}>
            <div className='editor-insertVideoModal-footer-YTIconContainer'>
              <img
                className='editor-insertVideoModal-footer-YTIconContainer-youtubeLogo'
                alt=''
                src='https://s3.amazonaws.com/assets.plickers.com/react-assets/images/yt_logo_rgb_light.png'
              />
            </div>
            <div className='editor-insertVideoModal-footer-YTLinkContainer'>
              <a
                className='editor-insertVideoModal-footer-YTLinkContainer-link thirdPartyAttributionLink'
                href='https://www.youtube.com/t/terms'
                target='_blank'
                rel='noopener noreferrer'
              >
                Youtube Terms of Service
              </a>
            </div>
          </div>
          )}
          {noResults && (
          <div className='editor-insertVideoModal-resultsContainer'>
            <div className='editor-insertVideoModal-results--noResults'>
              No results found
            </div>
          </div>
          )}
          {searchResults.length !== 0 && (
          <div className='editor-insertVideoModal-resultsContainer' id='ytSearchResults'>
            {this.state.searchResults.map((video) => (
              <EditorInsertVideoModalVideoResult
                key={video.id.videoId}
                title={video.snippet.title}
                author={video.snippet.channelTitle}
                videoID={video.id.videoId}
                description={video.snippet.description}
                duration='-'
                publishedTimestamp='-'
                viewCount='-'
                snippet={video.snippet}
                  // hasCC
                selectVideo={this.selectVideo}
              />
            ))}
            {/* {searchResults.length>0 && isSearching ===false && searchResults.length < parseInt(this.props.searchResultCount,10) && */}
            {searchResults.length > 0 && isSearching === false && (
            <div onClick={this.handleLoadMore}>
              <div className='editor-insertVideoModal-resultsContainer-loadMoreContainer'>
                <button className='editor-insertVideoModal-resultsContainer-loadMoreBtn'>
                  Load more results
                </button>
              </div>
            </div>
            )}

            {isSearching && searchResults.length > 0 && (
            <div>
              <div className='editor-insertVideoModal-resultsContainer-loadMoreContainer'>
                <button className='editor-insertVideoModal-resultsContainer-loadMoreBtn editor-insertVideoModal-resultsContainer-loadMoreBtn--loading'>
                  Loading
                </button>
              </div>
            </div>
            )}
          </div>
          )}
        </div>
        <div className='editor-insertVideoModalContainerBG' onClick={this.props.closeInsertVideoModal} />
      </div>
    )
  }
}

export default EditorInsertVideoModal
